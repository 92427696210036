export default defineNuxtRouteMiddleware(async (to, from) => {
    const nuxtApp = useNuxtApp();

    if (! nuxtApp.$me.check()) {
        let loginPath = await nuxtApp.$localePath('login');
        return await navigateTo(loginPath);
    }

    if(! nuxtApp.$me.isAdmin()) {
        let homePath = await nuxtApp.$localePath('index');
        return await navigateTo(homePath);
    }
})
